import {
  CLEAR_ERRORS,
  DELETE_USER_FAIL,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  FORGOT_PASSWORD_FAIL,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  LOAD_USER_FAIL,
  LOAD_USER_REQUEST,
  LOAD_USER_SUCCESS,
  LOGIN_FAIL,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  NEW_PASSWORD_FAIL,
  NEW_PASSWORD_REQUEST,
  NEW_PASSWORD_SUCCESS,
  REGISTER_FAIL,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  UPDATE_PASSWORD_FAIL,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PROFILE_FAIL,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_USER_FAIL,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
} from "../constants/authConstants";
import axios from "axios";
import { config } from "./requestHeadersConfig";
import { toastEmitter } from "../utils/utils";
import { capitalize } from "lodash";

export const login = (email, password) => async (dispatch) => {
  try {
    dispatch({ type: LOGIN_REQUEST });
    const { data } = await axios.post(
      "/api/login",
      { email, password },
      config
    );
    dispatch({ type: LOGIN_SUCCESS, payload: data });
  } catch (error) {
    const errorMsg = error.response.data.message;
    toastEmitter(errorMsg, "error");
    dispatch({ type: LOGIN_FAIL, payload: errorMsg });
  }
};

export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};

export const register = (userData) => async (dispatch) => {
  try {
    dispatch({ type: REGISTER_REQUEST });
    const { data } = await axios.post("/api/register", userData, config);
    toastEmitter("Your have created your account successfully!", "success");
    dispatch({ type: REGISTER_SUCCESS, payload: data.user });
  } catch (error) {
    const errorMsg = error.response.data.message;
    toastEmitter(errorMsg, "error");
    dispatch({ type: REGISTER_FAIL, payload: errorMsg });
  }
};

export const loadUser = () => async (dispatch) => {
  try {
    dispatch({ type: LOAD_USER_REQUEST });
    const { data } = await axios.get("/api/me", config);
    dispatch({ type: LOAD_USER_SUCCESS, payload: data.user });
  } catch (error) {
    dispatch({ type: LOAD_USER_FAIL, payload: error.response.data.message });
  }
};

export const logoutUser = () => async (dispatch) => {
  try {
    await localStorage.removeItem("token");
    dispatch({ type: LOGOUT_SUCCESS });
  } catch (error) {
    dispatch({ type: LOGIN_FAIL, payload: error });
  }
};

export const updateProfile = (userData) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_PROFILE_REQUEST });
    const {
      data: { user },
    } = await axios.put("/api/me/update", userData, config);
    toastEmitter("Your profile is updated successfully!", "success");
    dispatch({ type: UPDATE_PROFILE_SUCCESS, payload: user });
  } catch (error) {
    const errorMsg = error.response.data.message;
    toastEmitter(errorMsg, "error");
    dispatch({
      type: UPDATE_PROFILE_FAIL,
      payload: errorMsg,
    });
  }
};

export const updatePassword = (password) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_PASSWORD_REQUEST });
    const { data } = await axios.put("/api/password/update", password, config);
    toastEmitter("Your password is updated successfully!", "success");
    dispatch({ type: UPDATE_PASSWORD_SUCCESS, payload: data.success });
  } catch (error) {
    const errorMsg = error.response.data.message;
    toastEmitter(errorMsg, "error");
    dispatch({
      type: UPDATE_PASSWORD_FAIL,
      payload: errorMsg,
    });
  }
};

export const forgotPassword = (email) => async (dispatch) => {
  try {
    dispatch({ type: FORGOT_PASSWORD_REQUEST });
    const {
      data: { message },
    } = await axios.post("/api/password/forgot", email, config);
    toastEmitter(capitalize(message), "success");
    dispatch({ type: FORGOT_PASSWORD_SUCCESS });
  } catch (error) {
    const errorMsg = error.response.data.message;
    toastEmitter(errorMsg, "error");
    dispatch({
      type: FORGOT_PASSWORD_FAIL,
      payload: errorMsg,
    });
  }
};

export const resetPassword = (token, passwords) => async (dispatch) => {
  try {
    dispatch({ type: NEW_PASSWORD_REQUEST });
    const { data } = await axios.put(
      `/api/password/reset/${token}`,
      passwords,
      config
    );
    toastEmitter("Your password is updated successfully!", "success");
    dispatch({ type: NEW_PASSWORD_SUCCESS, payload: data });
  } catch (error) {
    const errorMsg = error.response.data.message;
    toastEmitter(errorMsg, "error");
    dispatch({
      type: NEW_PASSWORD_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const updateUser = (id, userData) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_USER_REQUEST });
    const { data } = await axios.put(`/api/admin/user/${id}`, userData, config);
    dispatch({ type: UPDATE_USER_SUCCESS, payload: data.success });
  } catch (error) {
    dispatch({
      type: UPDATE_USER_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const deleteUser = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_USER_REQUEST });
    const { data } = await axios.delete(`/api/admin/user/${id}`);
    dispatch({ type: DELETE_USER_SUCCESS, payload: data.success });
  } catch (error) {
    dispatch({
      type: DELETE_USER_FAIL,
      payload: error.response.data.message,
    });
  }
};
