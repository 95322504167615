import React from "react";
import { Route, Routes } from "react-router-dom";
import ProtectedRoute from "./components/protectedRoute/ProtectedRoute";

const LandingPage = React.lazy(() => import("./pages/landingPage/LandingPage"));
const ModelsPage = React.lazy(() => import("./pages/modelsPage/ModelsPage"));
const GenerationsPage = React.lazy(() =>
  import("./pages/generationsPage/GenerationsPage")
);
const TrimsPage = React.lazy(() => import("./pages/trimsPage/TrimsPage"));
const TrimPage = React.lazy(() => import("./pages/trimPage/TrimPage"));
const About = React.lazy(() => import("./pages/about/About"));
const TermsOfUse = React.lazy(() => import("./pages/termsOfUse/TermsOfUse"));
const Contact = React.lazy(() => import("./pages/contact/Contact"));
const Docs = React.lazy(() => import("./pages/docs/Docs"));
const LoginPage = React.lazy(() => import("./pages/loginPage/LoginPage"));
const RegisterPage = React.lazy(() =>
  import("./pages/registerPage/RegisterPage")
);
const APIPage = React.lazy(() => import("./pages/apiPage/ApiPage"));
const SettingsPage = React.lazy(() =>
  import("./pages/settingsPage/SettingsPage")
);
const PasswordForgotPage = React.lazy(() =>
  import("./pages/passwordForgotPage/PasswordForgotPage")
);
const PasswordResetPage = React.lazy(() =>
  import("./pages/passwordResetPage/PasswordResetPage")
);

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} exact />
      <Route path="/brand/:id" element={<ModelsPage />} exact />
      <Route path="/model/:id" element={<GenerationsPage />} exact />
      <Route path="/generation/:id" element={<TrimsPage />} exact />
      <Route path="/trim/:id" element={<TrimPage />} exact />
      <Route path="/about" element={<About />} exact />
      <Route path="/terms-of-use" element={<TermsOfUse />} exact />
      <Route path="/contact" element={<Contact />} exact />
      <Route path="/docs" element={<Docs />} exact />
      <Route path="/login" element={<LoginPage />} exact />
      <Route path="/register" element={<RegisterPage />} exact />
      <Route element={<ProtectedRoute />}>
        <Route path="/:userName/settings" element={<SettingsPage />} />
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route path="/:userName/api" element={<APIPage />} />
      </Route>
      <Route path="/password/forgot" element={<PasswordForgotPage />} exact />
      <Route
        path="/password/reset/:token"
        element={<PasswordResetPage />}
        exact
      />
    </Routes>
  );
};

export default AppRoutes;
