import React from 'react';
import './Footer.css';
import { arePropsEqual, getCurrentYear } from '../../utils/utils';

const Footer = () => {
  return (
    <footer className='footer'>
      <div className='footer-links'>
        <a className='footer-link' href='/about'>
          about
        </a>
        <a className='footer-link' href='/terms-of-use'>
          terms of use
        </a>
        <a className='footer-link' href='/contact'>
          contact
        </a>
      </div>
      <span className='footer-rights'>
        &#169;
        {` Cars-Specs - 2023-${getCurrentYear()}, All Copyrights Reserved`}
      </span>
    </footer>
  );
};

export default React.memo(Footer, arePropsEqual);
