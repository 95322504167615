import {
  GET_BRANDS_REQUEST,
  GET_BRANDS_SUCCESS,
  GET_BRANDS_FAIL,
  GET_MODEL_REQUEST,
  GET_MODEL_SUCCESS,
  GET_MODEL_FAIL,
  GET_GENERATION_REQUEST,
  GET_GENERATION_SUCCESS,
  GET_GENERATION_FAIL,
  GET_TRIM_REQUEST,
  GET_TRIM_SUCCESS,
  GET_TRIM_FAIL,
} from '../constants/carsConstants';

import {
  GET_BRAND_REQUEST,
  GET_BRAND_SUCCESS,
  GET_BRAND_FAIL,
} from '../constants/carsConstants';

export const carsReducer = (
  state = {
    brands: [],
    brand: {},
    model: {},
    generation: {},
    trim: {},
    loading: false,
    error: null,
  },
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_BRANDS_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_BRANDS_SUCCESS:
      return {
        loading: false,
        brands: payload,
        error: null,
      };
    case GET_BRANDS_FAIL:
      return { loading: false, error: payload };

    case GET_BRAND_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_BRAND_SUCCESS:
      return {
        ...state,
        loading: false,
        brand: payload,
        error: null,
      };
    case GET_BRAND_FAIL:
      return { loading: false, error: payload };

    case GET_MODEL_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_MODEL_SUCCESS:
      return {
        ...state,
        loading: false,
        ...payload,
        error: null,
      };
    case GET_MODEL_FAIL:
      return { loading: false, error: payload };

    case GET_GENERATION_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_GENERATION_SUCCESS:
      return {
        ...state,
        loading: false,
        ...payload,
        error: null,
      };
    case GET_GENERATION_FAIL:
      return { loading: false, error: payload };

    case GET_TRIM_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_TRIM_SUCCESS:
      return {
        ...state,
        loading: false,
        ...payload,
        error: null,
      };
    case GET_TRIM_FAIL:
      return { loading: false, error: payload };

    default:
      return state;
  }
};
