import { isArray, isDate, isEmpty, isEqual, isString } from "lodash";
import { useEffect, useRef } from "react";
import { toast } from "react-toastify";

//custom hook to get previous value of a state
export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value; //assign the value of ref to the argument
  }, [value]); //this code will run when the value of 'value' changes
  return ref.current; //in the end, return the current ref value.
}

export const arePropsEqual = (previousProps, nextProps) =>
  isEqual(previousProps, nextProps);

export const formulateLinkParametersAndValue = (parameter, values) => {
  return isEmpty(values)
    ? ""
    : values.reduce((acc, value) => (acc += `&${parameter}=${value}`), "");
};

export const getScreenWidth = () => {
  return window.screen.width;
};

export const getCurrentYear = () => {
  return new Date().getFullYear();
};

export const isArrayNotEmpty = (arr) => {
  return isArray(arr) && !isEmpty(arr);
};

export const isUrl = (str) => {
  return isString(str) && str.includes("http");
};

export const roundToNearestTenth = (number) => {
  return Math.round(number * 10) / 10;
};

export const getDateFromTimestamp = (timestamp) => {
  if (!timestamp) return "";
  const date = new Date(timestamp);

  if (isDate(date)) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based, so add 1
    const day = String(date.getDate()).padStart(2, "0");

    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
  } else return "";
};

export async function copyToClipboard(text, message) {
  if (navigator.clipboard) {
    try {
      await navigator.clipboard.writeText(text);
      toastEmitter(message, "success");
    } catch (err) {
      console.error("Failed to copy text to clipboard", err);
    }
  }
}

export const toastEmitter = (text, type = "default", fontSize = "16px") => {
  toast(text, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
    type,
    style: { fontSize },
  });
};

export function formatCloudinaryUrl(url, height, width) {
  if (!url) return "";
  // Ensure the URL uses https
  let secureUrl = url.replace("http://", "https://");

  // Find the part of the URL where transformations are applied
  const uploadIndex = secureUrl.indexOf("/upload/");
  if (uploadIndex !== -1) {
    // Insert the transformation part '/w_54,h_54/' after '/upload/'
    const sizeSpecs = `w_${width},h_${height}/`;
    secureUrl =
      secureUrl.slice(0, uploadIndex + 8) +
      sizeSpecs +
      secureUrl.slice(uploadIndex + 8);
  }

  return secureUrl;
}
