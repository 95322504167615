import React, { useEffect, useRef, useState } from "react";
import "./HeaderDropdown.css";

const HeaderDropdown = ({ options, showDropDownFct }) => {
  const dropdownRef = useRef(null);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    };
  }, [showDropDownFct]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        isMounted &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        showDropDownFct(false);
      }
    }
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isMounted, showDropDownFct]);

  return (
    <div ref={dropdownRef}>
      <div className="header-dropdown">
        {options?.map((option, index) => (
          <div className="dropdown-option" key={index}>
            <span className="option-icon"> {option?.icon}</span>
            <span
              key={index}
              className="dropdown-option-txt"
              onClick={() => option.optionClickFct()}
            >
              {option?.label}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HeaderDropdown;
