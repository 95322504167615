export const GET_BRANDS_REQUEST = 'GET_BRANDS_REQUEST';
export const GET_BRANDS_SUCCESS = 'GET_BRANDS_SUCCESS';
export const GET_BRANDS_FAIL = 'GET_BRANDS_FAIL';

export const GET_BRAND_REQUEST = 'GET_BRAND_REQUEST';
export const GET_BRAND_SUCCESS = 'GET_BRAND_SUCCESS';
export const GET_BRAND_FAIL = 'GET_BRAND_FAIL';

export const GET_MODEL_REQUEST = 'GET_MODEL_REQUEST';
export const GET_MODEL_SUCCESS = 'GET_MODEL_SUCCESS';
export const GET_MODEL_FAIL = 'GET_MODEL_FAIL';

export const GET_GENERATION_REQUEST = 'GET_GENERATION_REQUEST';
export const GET_GENERATION_SUCCESS = 'GET_GENERATION_SUCCESS';
export const GET_GENERATION_FAIL = 'GET_GENERATION_FAIL';

export const GET_TRIM_REQUEST = 'GET_TRIM_REQUEST';
export const GET_TRIM_SUCCESS = 'GET_TRIM_SUCCESS';
export const GET_TRIM_FAIL = 'GET_TRIM_FAIL';
