import React from 'react';
import './Loader.css';
import carSports from '../../assets/pics/car-sports.png';

const Loader = () => {
  return (
    <div className='loader'>
      <div className='vertical-loop-road'>
        <div className='horizontal-road'>
          <img src={carSports} alt='model pic' className='loader-car' />
        </div>
      </div>
    </div>
  );
};

export default Loader;
