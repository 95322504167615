import React, { Suspense, useEffect } from "react";
import "./pages/landingPage/LandingPage.css";
import "./App.css";
// import "./pages/loginPage/LoginPage.css";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Loader from "./components/loader/Loader";
import { BrowserRouter as Router } from "react-router-dom";
import SitemapRoutes from "./sitemap-routes";
import { useDispatch } from "react-redux";
import { loadUser } from "./actions/authActions";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) dispatch(loadUser());
  }, [dispatch]);

  return (
    <div className="app">
      <Router>
        <Header />
        <main className="main">
          <Suspense fallback={<Loader />}>
            <SitemapRoutes />
          </Suspense>
        </main>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
