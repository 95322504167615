import React from "react";

const AvatarIcon = () => {
  return (
    <svg
      width="60px"
      height="60px"
      viewBox="0 0 36 36"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      role="img"
      className="iconify iconify--twemoji header-user-pic"
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        fill="#CCD6DD"
        d="M36 36v-2a6 6 0 0 0-6-6H14a6 6 0 0 0-6 6v2h28z"
      ></path>
      <path
        fill="#D4AB88"
        d="M17.64 28.038c0 2.846 8.72 2.962 8.72 0v-3.749h-8.72v3.749z"
      ></path>
      <path
        fill="#CC9B7A"
        d="M17.632 25.973c1.216 1.374 2.724 1.746 4.364 1.746c1.639 0 3.147-.373 4.363-1.746v-3.491h-8.728v3.491z"
      ></path>
      <path
        fill="#963B22"
        d="M25.152 3.3c-1.925-.623-5.876-.46-7.008 1.012c-2.944.057-6.083 2.932-6.536 6.443c-.448 3.475.235 4.874.591 7.486c.403 2.96 2.067 3.907 3.397 4.303c1.914 2.529 3.949 2.421 7.366 2.421c6.672 0 9.271-4.458 9.552-12.04c.169-4.585-2.522-8.059-7.362-9.625z"
      ></path>
      <path
        fill="#D4AB88"
        d="M29.547 13.273c-.646-.894-1.472-1.614-3.284-1.868c.68.311 1.331 1.387 1.416 1.982c.085.595.17 1.076-.368.481c-2.155-2.382-4.502-1.444-6.827-2.899c-1.624-1.016-2.119-2.141-2.119-2.141s-.198 1.5-2.661 3.029c-.714.443-1.566 1.43-2.038 2.888c-.34 1.048-.234 1.982-.234 3.578c0 4.66 3.841 8.578 8.578 8.578s8.578-3.953 8.578-8.578c-.002-2.898-.305-4.031-1.041-5.05z"
      ></path>
      <path
        fill="#C1694F"
        d="M22.134 24.686c-2.754 0-3.6-.705-3.741-.848a.655.655 0 0 1 .902-.95c.052.037.721.487 2.839.487c2.2 0 2.836-.485 2.842-.49a.638.638 0 0 1 .913.015a.669.669 0 0 1-.014.938c-.142.143-.987.848-3.741.848m.827-3.979h-1.906a.477.477 0 1 1 0-.954h1.906a.477.477 0 1 1 0 .954z"
      ></path>
      <path
        fill="#662113"
        d="M18.195 17.37a.953.953 0 0 1-.953-.953v-.953a.953.953 0 0 1 1.906 0v.953a.952.952 0 0 1-.953.953zm7.626 0a.953.953 0 0 1-.953-.953v-.953a.953.953 0 0 1 1.906 0v.953a.953.953 0 0 1-.953.953z"
      ></path>
      <path fill="#269" d="M13 28h3v8h-3zm15 0h3v8h-3z"></path>
      <path fill="#269" d="M13 31h18v5H13z"></path>
      <ellipse
        fill="#F5F8FA"
        cx="27.75"
        cy="33.75"
        rx="2.25"
        ry="1.25"
      ></ellipse>
      <path
        fill="#3B88C3"
        d="M31 9.5c0 1.32-18 1.32-18 0C13 5.954 14 1 22 1c7 0 9 4.954 9 8.5z"
      ></path>
      <path
        fill="#269"
        d="M13.056 9.5s-.5 3 1.5 3c1 0 2.5-2 7.5-2s6.5 2 7.5 2c2 0 1.5-3 1.5-3s-1.5.5-9 .5s-9-.5-9-.5z"
      ></path>
      <path
        fill="#55ACEE"
        d="M13 9s-.5 3 1.5 3c1 0 2.5-2 7.5-2s6.5 2 7.5 2c2 0 1.5-3 1.5-3s-1.5.5-9 .5s-9-.5-9-.5z"
      ></path>
      <path fill="#269" d="M20.5 1.5S20.5 0 22 0s1.5 1.5 1.5 1.5h-3z"></path>
      <path
        fill="#66757F"
        d="M9.501 23.635c-.005-1.805-1.026-3.353-2.511-4.153l.012 4.409c0 .553-1.006 1.003-2.249 1.005c-1.242.003-2.25-.443-2.251-.995l-.01-4.408c-1.48.806-2.494 2.358-2.492 4.163c.006 2.091 1.37 3.845 3.25 4.475V36h3v-7.869c1.888-.632 3.256-2.397 3.251-4.496z"
      ></path>
    </svg>
  );
};

export default AvatarIcon;
