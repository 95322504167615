import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { carsReducer } from "./reducers/carsReducer";
import { authReducer } from "./reducers/authReducer";

const reducer = combineReducers({
  cars: carsReducer,
  auth: authReducer,
});
const initialState = {};
const middleware = [thunk];
const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
